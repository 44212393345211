import React from 'react'

const HeadingWithText = ({ heading, text }) => {
  return (
    <p>
      <strong>{heading}</strong> - {text}
    </p>
  )
}

export default HeadingWithText
