import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PrivacyPolicyContainer from '../containers/PrivacyPolicyContainer'

const PolicyPage = (props) => {
  return (
    <Layout
      pageInfo={{ pageName: "About Us" }}
      styleClass="home-page"
      mainClass="main-about"
      location={props.location.pathname}>
      <SEO title="Privacy Policy" />
      <PrivacyPolicyContainer />
    </Layout>
  )
}

export default PolicyPage
