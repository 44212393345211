import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PrivacyContent from '../components/privacy/content'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/blogs/banner.png'

const PrivacyPolicyContainer = (props) => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <BannerImage
              noText={true}
              activeTab="Privacy Policy"
              bannerImage={banner} />

          </Col>
        </Row>
      </Container>
      <Container className='mt-4'>
        <Row>
          <Col>
            <PrivacyContent />
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default PrivacyPolicyContainer
